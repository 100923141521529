import { matchingTarget, targetedEventListener } from "../lib/targetedEventListener";

const DEFAULT_FALLBACK_SELECTOR = 'body';

/**
 * @param {String} selector
 * @param {Boolean} inline
 */
export const initDynamicLinks = (selector, fallbackSelector = DEFAULT_FALLBACK_SELECTOR, inline = false) => {
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }

    const container = document.querySelector(selector);

    window.history.replaceState({
        href: `${window.location}`,
        selector,
    }, null, '');

    window.addEventListener('popstate', function (data) {
        const state = data.state ?? {};

        if (state.href && state.selector) {
            loadDynamicLink(null, state.href, state.selector, state.fallbackSelector, state.inline, state.scrollPosition, false);
        }
    });

    container.addEventListener('click', targetedEventListener('a', function (event, target) {
        if (target.dataset.dynamicTarget) {
            return;
        }

        if (target.href) {
            const scrollPosition = inline ? { x: window.scrollX, y: window.scrollY } : null;

            loadDynamicLink(event, target.href, selector, fallbackSelector, inline, scrollPosition);
        }
    }));

    document.addEventListener('click', targetedEventListener('[data-dynamic-target]', function (event, target) {
        if (target.href && target.dataset.dynamicTarget) {
            const inline = target.dataset.dynamicInline !== false;
            const scrollPosition = inline ? { x: window.scrollX, y: window.scrollY } : null;

            loadDynamicLink(event, target.href, target.dataset.dynamicTarget, selector, inline, scrollPosition);
        }
    }));
}

/**
 * @param {Event} event
 * @param {String} href
 * @param {String} selector
 * @param {String} fallbackSelector
 * @param {Boolean} inline
 * @param {{ x: Number, y: Number }|null} scrollPosition
 * @param {Boolean} push
 */
export const loadDynamicLink = (event, href, selector, fallbackSelector, inline, scrollPosition, push = true) => {
    const target = document.querySelector(selector);
    const fallbackTarget = getFallbackElement(fallbackSelector);

    const element = target ? target : fallbackTarget;
    const elementSelector = target ? selector : fallbackSelector;
    const inlineUpdate = target ? inline : false;

    if (event) {
        event.preventDefault();
    }

    const state = {
        href,
        selector,
        fallbackSelector,
        inline,
        scrollPosition,
    };

    if (push) {
        history.pushState(state, null, href);
    }

    fetchDocument(href).then((doc) => {
        const content = doc.querySelector(elementSelector);

        if (content) {
            element.innerHTML = content.innerHTML;

            if (inlineUpdate) {
                // Do nothing
            } else if (scrollPosition) {
                window.scrollTo(scrollPosition.x, scrollPosition.y);
            } else {
                window.scrollTo(0, 0);
            }

            updateHead(doc);
        }
    });
}

/**
 * @param {String} fallbackSelector
 *
 * @returns {Element}
 */
const getFallbackElement = (fallbackSelector) => {
    const element = document.querySelector(fallbackSelector);

    return element ? element : document.body;
}

/**
 * @async
 * @param {String} url
 *
 * @returns {Promise<Document>}
 */
const fetchDocument = async (url) => {
    const response = await fetch(url);
    const text = await response.text();

    const parser = new DOMParser();
    return parser.parseFromString(text, 'text/html');
}

/**
 * @param {Document} doc
 */
const updateHead = (doc) => {
    const currentNodesOuterHtml = [...document.head.childNodes]
        .map((node) => node.outerHTML);

    const updatedNodesOuterHtml = [...doc.head.childNodes]
        .map((node) => node.outerHTML);

    [...document.head.childNodes].forEach((node) => {
        // If a current node also exists in the updated nodes, do nothing
        if (updatedNodesOuterHtml.includes(node.outerHTML)) {
            return;
        }

        // Otherwise, remove the existing node
        document.head.removeChild(node);
    });

    [...doc.head.childNodes].forEach((node) => {
        // If an updated node also exists in the current nodes, do nothing
        if (currentNodesOuterHtml.includes(node.outerHTML)) {
            return;
        }

        // Otherwise, add the existing node
        document.head.appendChild(node);
    });
}
